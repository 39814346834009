import React from "react";
import styled from "styled-components";
import { useTranslation } from "hooks/i18n";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

function FourOhFour() {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <h1>{t("pages.404.label")}</h1>
    </Wrapper>
  );
}

export default FourOhFour;
